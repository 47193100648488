// import React from "react";
// import { StaticQuery, graphql } from 'gatsby';
// import NewsQuilt from "src/components/news-quilt.js";

// /**
//  *  News quilt for grants category
//  */
// class NewsQuiltGrants extends React.Component {
//   render() {
//     return (
//       <StaticQuery
//           query={graphql`
//             query {
//               allWordpressPost(
//                 filter: {
//                   categories: {
//                     elemMatch: {
//                       name: {
//                         eq: "Grants"
//                       }
//                     }
//                   }
//                 }
//                 limit: 3
//                 sort: { fields: [date], order: DESC }
//               ) {
//                 edges {
//                   node {
//                     ...NewsQuilt
//                   }
//                 }
//               }
//             }
//           `}
//           render={data => (
//             <NewsQuilt 
//               newsQuiltData={data.allWordpressPost} 
//               quiltPanelsCount={this.props.quiltPanelsCount} 
//               quiltStyle={this.props.quiltStyle} 
//             />
//           )}
//         />
//     );
//   }
// }

// export default NewsQuiltGrants;










import React from "react";
import NewsQuilt from "src/components/news-quilt.js";

/**
 *  News quilt for Grants category
 */
class NewsQuiltGrants extends React.Component {

  state = {
    newsQuiltInfo: []
  }

  componentDidMount() {
    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/posts?categories=332&per_page=3`)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ newsQuiltInfo: json });
        }
      })
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.newsQuiltInfo.length > 0 &&
          <NewsQuilt
            newsQuiltData={this.state.newsQuiltInfo}
            quiltPanelsCount={this.props.quiltPanelsCount}
            quiltStyle={this.props.quiltStyle}
          />
        }
      </React.Fragment>
    );
  }
}

export default NewsQuiltGrants;
