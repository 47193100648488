import React from "react";
import photo from "src/images/community/community-grants-sunset.jpg";
import {Link} from "gatsby";

/**
 *  Info module on Community Grants Program
 */
class CommunityGrantsInfo extends React.Component {
  render() {
    return (
      <section className="info info--grants gutter-rt">
        <img src={photo} alt="" className="media__asset info__img community-grants-info-img" />
        <div className="gutter">
          <h2 className="heading heading--med info__heading line">About the Community Grants Program</h2>
          <p className="intro">
            The Community Grants Program is an unprecedented effort to lessen the Port of Long Beach’s impacts on the
            community. Over the next 12 to 15 years, we plan to invest $46.4 million toward community-based projects
            that reduce our impacts on air quality, traffic, noise, and water quality.
          </p>

          <p className="intro">
            The investment adds to the $17.4 million the Port of Long Beach has awarded to community groups since 2009,
            for a total of almost $65 million. No other U.S. seaport has voluntarily committed as much funding for
            community-based environmental mitigation.
          </p>

          <p className="intro">
            If you would like to receive our Community Grants Newsletter, sign up in the footer of this page under Stay Connected.
          </p>

          <p className="intro">
            <Link to="/subscribe">To sign up for Community Grants email updates, click here and choose “Community Grants Program” under News Updates.</Link>
          </p>

          <p className="intro">
            <a href={`${process.env.GATSBY_API_URL}/download/522/guidelines/15260/community-grants-program-fact-sheet-110320.pdf`}>Community Grants Fact Sheet</a>
          </p>
        </div>
      </section>
    );
  }
}

export default CommunityGrantsInfo;